<template>
  <div class="wrap">
    <div class="title">OPENIOT.AI</div>

    <van-form @submit="submit" autocomplete="off">
      <van-field
        v-model="state.phone"
        name="phone"
        placeholder="用户名"
        :rules="[{ required: true, message: '请输入用户名' }]"
      />
      <van-field
        v-model="state.password"
        name="password"
        placeholder="密码"
        type="password"
        :rules="[{ required: true, message: '请输入密码' }]"
      />
      <van-button round block type="primary" native-type="submit"
        >登录</van-button
      >
    </van-form>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive, getCurrentInstance, defineComponent } from "vue";
import { Toast } from "vant";

export default defineComponent({
  setup() {
    const router = useRouter();

    const state = reactive({
      phone: "",
      password: "",
    });

    const { proxy } = getCurrentInstance();

    const submit = async (values) => {
      let res = await proxy.axios.post("/api/user/login", values);
      if (res.code == 200 && res.msg == "success") {
        localStorage.setItem("user_info", JSON.stringify(res.data));
        Toast("登录成功！");
        router.push({ name: "entry" });
      } else {
        Toast("用户名或密码不正确！");
      }
    };
    return {
      state,
      submit,
    };
  },
});
</script>

<style scoped lang="scss">
.wrap {
  padding: 50% 0.1rem 0;
  .title {
    margin: 0 0 0.1rem;
    font-size: 0.22rem;
  }
  .van-field,
  .van-button {
    font-size: 0.14rem !important;
  }
  .van-button {
    margin-top: 0.1rem;
  }
}
</style>
